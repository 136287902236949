import { environment } from 'environments/environment';

export const getFullUrtForFile = (url: string | undefined): string => {
  return url
    ? url.includes('data:image') || url.includes(environment.apiUrlImg)
      ? url
      : url.includes('/storage/')
        ? `${environment.apiUrlImg}${url}`
        : `${environment.apiUrlImg}/storage/${url}`
    : '';
};
